import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import serviceIcon1 from '../../assets/images/services/service-icon1.png'
import serviceIcon2 from '../../assets/images/services/service-icon2.png'
import serviceIcon3 from '../../assets/images/services/service-icon3.png'
import serviceIcon4 from '../../assets/images/services/service-icon4.png'
import serviceIcon5 from '../../assets/images/services/service-icon5.png'
import serviceIcon6 from '../../assets/images/services/service-icon6.png'

const OurFeatures = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="feature" /> 
                        Avantajele noastre
                    </span>

                    <h2>Suntem aici pentru a ajuta</h2>
                    <p></p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon1} alt="feature" />
                            </div>
                            <h3>Software la comandă</h3>
                            <p>Transformă afacerea ta cu software personalizat adaptat nevoilor și obiectivelor unice.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon2} alt="feature" />
                            </div>
                            <h3>Dezvoltare de produs</h3>
                            <p>Convertește-ți ideile în soluții software inovatoare cu ajutorul echipei noastre experimentate de dezvoltare de produse.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon3} alt="feature" />
                            </div>
                            <h3>Testare software</h3>
                            <p>Asigurați-vă calitatea și fiabilitatea software-ului cu serviciile noastre de testare complete.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon4} alt="feature" />
                            </div>
                            <h3>Securitate cibernetică</h3>
                            <p>Protejați-vă afacerea și datele sensibile în era digitală cu serviciile noastre de cibersecuritate de înaltă calitate.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon5} alt="feature" />
                            </div>
                            <h3>Management de proiect</h3>
                            <p>Eficientizați procesul de dezvoltare software și duceți proiectele la bun sfârșit cu echipa noastră experimentată de management de proiect.</p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <img src={serviceIcon6} alt="feature" />
                            </div>
                            <h3>Cursuri specializate</h3>
                            <p>Încurajați echipele să rămână la vârful industriei cu formări tehnice specializate adaptate nevoilor pieței.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurFeatures