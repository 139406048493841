import React from 'react'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'

const OurServices = () => {
    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={service1} alt="service" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Servicii
                                    </span>
                                    
                                    <h2>Dezvoltare de software la comandă</h2>
                                    <p>Soluții software inovatoare, personalizate pentru afacerea ta. Tu ne dai provocarea, noi găsim si construim soluția corectă.</p>
                                    <ul className="about-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            ERP
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            CRM
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Magazine online
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Portaluri web
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Aplicații mobile
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Aplicații pentru rezervări
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Sisteme de gestiune
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Management de documente
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            {/* Service Right Image Style */}
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Servicii
                                    </span>
                                    
                                    <h2>Transformare digitală</h2>
                                    <p>Te ajutăm cu dezvoltarea si integrarea pe verticală a soluțiilor de digitalizare specifice domeniului de activitate.</p>

                                    <ul className="our-mission-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Transport si logistică
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Ospitalitate si turism
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Fintech & banking
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Automotive
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Retail online
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Imobiliare
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Producție
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Media si telecomunicații
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={service2} alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}
        </React.Fragment>
    )
}

export default OurServices;