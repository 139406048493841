import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import blog1 from '../../assets/images/blog/blog-img1.jpg'
import blog5 from '../../assets/images/blog/blog-img5.jpg'
import blog6 from '../../assets/images/blog/blog-img6.jpg'
import user1 from '../../assets/images/user1.jpg'
import user2 from '../../assets/images/user2.jpg'
import user3 from '../../assets/images/user3.jpg'

const OurBlog = () => {
    return (
        <section className="blog-area pt-100 pb-70 bg-fffbf5">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" /> 
                        Blog
                    </span>
                    <h2>Cele mai recente articole</h2>
                    <p>Aflați din ultimele noastre cercetări și analize.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <Link to="/blog/5-motive-pentru-care-afacarea-software-personalizat/">
                                    <img src={blog1} alt="blog" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                       Aprilie 30, 2022
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog/5-motive-pentru-care-afacarea-software-personalizat/">
                                        5 motive pentru care afacerea ta are nevoie de software personalizat
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <Link to="/blog/provocari-transformare-digitala">
                                    <img src={blog5} alt="blog" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                         Mai 28, 2022
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog/provocari-transformare-digitala">
                                        Provocări și capcane ale inițiativelor de transformare digitală
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div className="single-blog-post">
                            <div className="post-image">
                                <Link to="/blog/strategie-transformare-digitala">
                                    <img src={blog6} alt="blog" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                         Iunie 29, 2022
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog/strategie-transformare-digitala">
                                    Cum să creezi o strategie de transformare digitală pentru afacerea ta
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurBlog