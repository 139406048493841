import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        Soluțiile noastre
                    </span>
                    <h2>Transformăm afacerile transformând tehnologia, câte o soluție pe rând</h2>
                    <p>Soluții inovatoare pentru o lume conectată, oferite de echipa noastră de experți.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/solutii/dezvoltare-software/">
                                    Realizare de produse software
                                </Link>
                            </h3>
                            <p>Transpunem ideile clienților în aplicații software puternice și ușor de utilizat, dezvoltate la cheie de echipa noastră de experți.</p>

                            <Link to="/solutii/dezvoltare-software/" className="view-details-btn">
                                Află mai multe
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                <Link to="/solutii/echipe-dedicate">
                                       Echipe dedicate
                                </Link>
                            </h3>

                            <p>Echipele noastre dedicate de profesioniști IT vă pot ajuta afacerea să-și extindă capacitățile și să-și maximizeze potențialul, cu suport de specialitate și soluții personalizate care conduc la succes.</p>
                            
                            <Link to="/solutii/echipe-dedicate" className="view-details-btn">
                                Află mai multe
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/solutii/consultanta-it">
                                    Consultanță IT
                                </Link>
                            </h3> 

                            <p>Consultanții noștri IT vă pot ajuta afacerea să exploateze întregul potențial al tehnologiei, cu soluții personalizate și îndrumări strategice care conduc la succes și optimizează performanța organizației.</p>
                            
                            <Link to="/solutii/consultanta-it" className="view-details-btn">
                                Află mai multe
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;